import * as actionTypes from './actionTypes';
import { getStates, getCountries } from '~/api/geo';

import { callApi } from './index';
import { cacheEntity } from './cache';

/**
 * Init
 */
export const init = app => {
    return ({
    type: actionTypes.INIT,
    app
})};

/**
 * API Actions
 */
export const startApiCall = () => ({
    type: actionTypes.START_API_CALL
});

export const endApiCall = () => ({
    type: actionTypes.END_API_CALL
});

/**
 * Notify
 */
export const notify = data => ({
    type: actionTypes.NOTIFY,
    notify: data
});

/**
 * Geo
 */
const fetchCountries = dispatch => {
    const promise = getCountries();

    callApi(promise, false);

    promise.then(response => {
        dispatch(cacheEntity(`countries`, response.data));
    });
};

export const loadCountries = (force = false) => (dispatch, getState) => {
    if (!force) {
        const data = getState().cache.countries;
        if (data) {
            return;
        }
    }

    fetchCountries(dispatch);
};

const fetchStates = (countryID, dispatch) => {
    const promise = getStates(countryID);

    callApi(promise, false);

    promise.then(response => {
        dispatch(cacheEntity(`states[${countryID}]`, response.data));
    });
};

export const loadStates = (countryID, force = false) => (dispatch, getState) => {
    if (!force) {
        const data = getState().cache.states[countryID];
        if (data) {
            return;
        }
    }

    fetchStates(countryID, dispatch);
};
