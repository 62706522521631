import Loader from 'Lib/utilities/Loader';

import Simple from '../containers/layouts/Simple';

const routes = [
    {
        path: '/documents/sign/:uuid',
        component: Loader(() => import('~/containers/documents/Sign')),
        layout: Simple
    }
];

export default routes;
