import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/leases/:id([0-9]+)/notifications/balance/unsubscribe',
        component: Loader(() => import('~/containers/leases/UnsubscribeBalanceNotification'))
    }
];

export default routes;
