export const SHOW_MENU = 'ui/SHOW_MENU';
export const SET_SAVED_SEARCHES = 'ui/SET_SAVED_SEARCHES';
export const SET_TABLE_INFORMATION = 'ui/SET_TABLE_INFORMATION';
export const SET_TABLE_VIEW = 'ui/SET_TABLE_VIEW';
export const TOGGLE_PINNED_DRAWER = 'ui/TOGGLE_PINNED_DRAWER';
export const PIN_DRAWER = 'ui/PIN_DRAWER';
export const CLOSE_PINNED_DRAWER = 'ui/CLOSE_PINNED_DRAWER';
export const TOGGLE_DRAWER = 'ui/TOGGLE_DRAWER';
export const SET_DRAWER_OPEN = 'ui/SET_DRAWER_OPEN';
export const SET_SEARCH = 'ui/SET_SEARCH';
export const REFRESH_ACTIVITIES = 'ui/REFRESH_ACTIVITIES';
export const REFRESH_WATCHERS = 'ui/REFRESH_WATCHERS';
export const SET_TABLE_PAGE_SIZE = 'ui/SET_TABLE_PAGE_SIZE';
