import { Component } from 'react';

class Simple extends Component {
    render() {
        const { component, route } = this.props;

        if (component) {
            return (
                <div>
                    <component route={route} />
                </div>
            );
        }

        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}

export default Simple;
