import { isFunction } from 'Lib/utilities/isFunction';
import { isPromise } from 'Lib/utilities/isPromise';

const promise = store => next => action => {
    if (!isPromise(action.payload)) {
        return next(action);
    }

    action.payload.then(
        responses => {
            action.error = false;
            action.payload = responses;

            if (action.successType) {
                action.type = action.successType;
            }

            if (isFunction(action.success)) {
                action.success(responses);
            }

            store.dispatch(action);
        },
        error => {
            action.payload = error;

            if (action.errorType) {
                action.type = action.errorType;
            }

            if (isFunction(action.error)) {
                action.error(error);
            }

            store.dispatch(action);
        }
    );

    return false;
};

export default promise;
