import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import app from '~/services/app/reducer';

export default history =>
    combineReducers({
        app,
        router: connectRouter(history)
    });
