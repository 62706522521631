import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/apply',
        component: Loader(() => import('~/containers/apply/Application'))
    },
    {
        path: '/apply/:id([0-9]+)',
        component: Loader(() => import('~/containers/apply/Application'))
    },
    {
        path: '/apply/receipt/:applicationID([0-9]+)/:applicantID([0-9]+)',
        component: Loader(() => import('~/containers/apply/Receipt'))
    },
    {
        path: '/apply?invitation=:queryID([0-9]+)',
        component: Loader(() => import('~/containers/apply/Application'))
    },
    {
        path: '/apply?application=:queryID([-w]+)',
        component: Loader(() => import('~/containers/apply/Application'))
    }
];

export default routes;
