import { request } from '~/agent';

export const getStates = countryID => {
    if (!countryID) {
        return request.get(`states`, { countryID: 'US' });
    }
    return request.get(`states`, { countryID });
};

export const getCountries = () => {
    return request.get(`countries`);
};
