import PortalTypes from 'Lib/constants/app/portalTypes';

import * as actionTypes from './actionTypes';

export const initialState = {
    isInitialized: false,
    portalTypeID: PortalTypes.Public
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT:
            return {
                ...action.app
            };
        default:
            return state;
    }
};

export default reducer;
