import { Component } from 'react';

class App extends Component {
    render() {
        const { component, route } = this.props;

        if (component) {
            return (
                <div id="default-layout">
                    <div className="main">
                        <component route={route} />
                    </div>
                </div>
            );
        }

        return (
            <div id="default-layout">
                <div className="main">{this.props.children}</div>
            </div>
        );
    }
}

export default App;
