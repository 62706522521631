import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/invoices',
        component: Loader(() => import('~/containers/invoices/Upload'))
    }
];

export default routes;
