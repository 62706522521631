import store from '~/store';
import agent from '~/agent';

import * as actions from './actions';

export const initialize = () => {
    agent.request
        .get('init')
        .then(response => {
            store.dispatch(
                actions.init({
                    domain: CONFIG.domain,
                    isInitialized: true,
                    hasError: false,
                    errorMessage: null,
                    account: response.data.account,
                    brandSettings: response.data.brandSettings,
                    screeningSettings: response.data.screeningSettings
                })
            );
        })
        .catch(() => {
            store.dispatch(
                actions.init({
                    isInitialized: false,
                    hasError: true,
                    errorMessage: 'Could not initialize the application.'
                })
            );
        });
};


/**
 * API
 */
export const callApi = (promise, exclude = false) => {
    if (!exclude) {
        store.dispatch(actions.startApiCall());
    }

    promise
        .catch(error => {
            // TODO: check the status code is 401
            if (error.response.status == 401) {
                store.dispatch();
            }
        })
        .finally(() => {
            if (!exclude) {
                store.dispatch(actions.endApiCall());
            }
        });
};
