import { toast } from 'react-toastify';

import Notify from 'Lib/components/modals/Notify';
import extractErrors from 'Lib/utilities/extractErrors';
import isObject from 'Lib/utilities/isObject';

const notify = () => next => action => {
    let item;
    let messages;

    if (isObject(action.notify)) {
        if (action.error === true) {
            delete action.notify.success;
        } else if (action.error === false) {
            delete action.notify.error;
        }

        if (action.notify.success) {
            toast.success(action.notify.success, {
                hideProgressBar: true,
                autoClose: 4000,
                pauseOnFocusLoss: false
            });
        }

        if (action.notify.error) {
            if (isObject(action.notify.error)) {
                item = action.notify.error;

                const errorResponse = item.data ? item.data : item;

                if (typeof errorResponse === 'object') {
                    extractErrors(toast, errorResponse);
                }

                if (item.includeErrors === true) {
                    messages = [];

                    Object.keys(action.errors).forEach(prop => {
                        if (Array.isArray(action.errors[prop])) {
                            action.errors[prop].forEach(msg => {
                                messages.push(msg);
                            });
                        } else {
                            messages.push(action.errors[prop]);
                        }
                    });

                    toast.error(<Notify message={item.message} messages={messages} />);
                }
            } else {
                toast.error(action.notify.error);
            }
        }

        if (action.notify.warn) {
            toast.warn(action.notify.warn);
        }

        if (action.notify.info) {
            toast.warn(action.notify.info);
        }

        if (action.notify.message) {
            toast(action.notify.message);
        }

        delete action.notify;
    }

    next(action);
};

export default notify;
