import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AnimatedLogo from 'Lib/assets/AnimatedLogo';

import * as app from '~/services/app';

const mapStateToProps = state => ({
    app: state.app
});

const mapDispatchToProps = {};

class IsInitialized extends Component {
    componentDidMount() {
        app.initialize();
    }

    render() {
        if (!this.props.app.isInitialized) {
            return <AnimatedLogo />;
        }

        return <Fragment>{this.props.children}</Fragment>;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IsInitialized));
