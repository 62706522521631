//import { ASYNCH_START, ASYNCH_END } from 'Lib/constants/app/actionTypes';

export const CALL_API = 'CALL_API';

export const api = () => next => action => {
    if (!action[CALL_API]) {
        return next(action);
    }

    const details = action[CALL_API];

    if (!details.promise) {
        return false;
    }

    /*
    if (details.showAsynch) {
        store.dispatch({ type: ASYNCH_START });
	}
	*/

    let promise = details.promise;
    if (details.promise.promise) {
        promise = details.promise.promise;
    }

    promise
        .then(response => {
            if (details.showAsynch) {
                //store.dispatch({ type: ASYNCH_END });
            }

            return response;
        })
        .catch(error => {
            if (details.showAsynch) {
                //store.dispatch({ type: ASYNCH_END });
            }

            // TODO: stuff for extreme errors
            return error;
        });

    return false;
};

export default api;
