import Loader from 'Lib/utilities/Loader';

import applications from './applications';
import rentalApplication from './apply';
import documents from './documents';
import invoices from './invoices';
import leases from './leases';
import listings from './listings';
import portfolios from './portfolios';

const routes = [
    {
        path: '/',
        component: Loader(() => import('~/containers/Home'))
    },
    ...invoices,
    ...listings,
    ...rentalApplication,
    ...documents,
    ...leases,
    ...portfolios,
    ...applications
];

export default routes;
