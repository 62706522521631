/**
 * Initialize
 */
export const INIT = 'app/INIT';

/**
 * API Actions
 */
export const START_API_CALL = 'app/START_API_CALL';
export const END_API_CALL = 'app/END_API_CALL';

/**
 * Notify
 */
export const NOTIFY = 'NOTIFY';
