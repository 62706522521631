import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/portfolios/:id([0-9]+)/notifications/balance/unsubscribe',
        component: Loader(() => import('~/containers/portfolios/UnsubscribeBalanceNotification'))
    },
    {
        path: '/owner/statements/:uuid',
        component: Loader(() => import('~/containers/portfolios/Statement'))
    }
];

export default routes;
