import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/listings',
        component: Loader(() => import('~/containers/listings/Index'))
    },
    {
        path: '/listings/:id([0-9]+)',
        component: Loader(() => import('~/containers/listings/View'))
    }
];

export default routes;
