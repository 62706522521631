import * as actionTypes from './actionTypes';

export const showMenu = isVisible => {
    return {
        type: actionTypes.SHOW_MENU,
        isVisible
    };
};

export const setSavedSearches = savedSearches => ({
    type: actionTypes.SET_SAVED_SEARCHES,
    savedSearches
});

export const setTableInformation = table => ({
    type: actionTypes.SET_TABLE_INFORMATION,
    table
});

export const togglePinnedDrawer = pinnedContentTypeID => ({
    type: actionTypes.TOGGLE_PINNED_DRAWER,
    pinnedContentTypeID
});

export const pinDrawer = pinnedContentTypeID => ({
    type: actionTypes.PIN_DRAWER,
    pinnedContentTypeID
});

export const closePinnedDrawer = () => ({
    type: actionTypes.TOGGLE_PINNED_DRAWER
});

export const setDrawerOpen = open => ({
    type: actionTypes.SET_DRAWER_OPEN,
    isDrawerOpen: open
});

export const toggleDrawer = drawerContentTypeID => ({
    type: actionTypes.TOGGLE_DRAWER,
    drawerContentTypeID
});

export const setSearch = search => ({
    type: actionTypes.SET_SEARCH,
    search
});

export const refreshActivities = () => ({
    type: actionTypes.REFRESH_ACTIVITIES
});

export const refreshWatchers = () => ({
    type: actionTypes.REFRESH_WATCHERS
});

export const setTableView = tableViewID => {
    localStorage.setItem('tableView', tableViewID);

    return {
        type: actionTypes.SET_TABLE_VIEW,
        tableViewID
    };
};

export const setTablePageSize = pageSize => {
    localStorage.setItem('tablePageSize', pageSize);

    return {
        type: actionTypes.SET_TABLE_PAGE_SIZE,
        pageSize
    };
};
