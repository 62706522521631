import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/landlord-verification/:uuid',
        component: Loader(() => import('~/containers/applications/LandlordVerification'))
    }
];

export default routes;
