import { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import SentryErrorBoundary from 'Lib/components/errors/SentryErrorBoundary';
import NotFound from 'Lib/components/layout/NotFound';
import QueryClientProvider from 'Lib/QueryClient';

import '~/scss/app.scss';
import 'react-toastify/dist/ReactToastify.css';

import IsInitialized from '~/components/IsInitialized';
import AppLayout from '~/containers/layouts/App';
import routes from '~/routes';

class App extends Component {
    render() {
        return (
            <>
                <Router basename="/public">
                    <IsInitialized>
                        <QueryClientProvider>
                            <Switch>
                                {routes.map((route, index) => (
                                    <Route
                                        key={index}
                                        exact={true}
                                        path={route.path}
                                        render={props => {
                                            let Layout = AppLayout;

                                            if (route.layout) {
                                                Layout = route.layout;
                                            }

                                            return (
                                                <Layout>
                                                    <SentryErrorBoundary>
                                                        <route.component route={props} routes={route.routes} />
                                                    </SentryErrorBoundary>
                                                </Layout>
                                            );
                                        }}
                                    />
                                ))}
                                <Route route="*" component={NotFound} />
                            </Switch>
                        </QueryClientProvider>
                    </IsInitialized>
                </Router>

                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
            </>
        );
    }
}

export default App;
